/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigation = {
    id: 'your-data',
    title: 'Your data',
    type: 'basic',
    icon: 'heroicons_outline:user',
    link: '/your-data',
} as FuseNavigationItem;

export const defaultNavigation: FuseNavigationItem[] = [navigation];
export const compactNavigation: FuseNavigationItem[] = [navigation];
export const futuristicNavigation: FuseNavigationItem[] = [navigation];
export const horizontalNavigation: FuseNavigationItem[] = [navigation];
