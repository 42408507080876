import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import {
    labels as labelsData,
    gifts as giftsData,
} from 'app/mock-api/apps/gifts/data';
import { FuseMockApiUtils } from '@fuse/lib/mock-api';

@Injectable({
    providedIn: 'root',
})
export class GiftsMockApi {
    private _labels: any[] = labelsData;
    private _gifts: any[] = giftsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Labels - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/gifts/labels')
            .reply(() => [200, cloneDeep(this._labels)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/gifts/labels')
            .reply(({ request }) => {
                // Create a new label
                const label = {
                    id: FuseMockApiUtils.guid(),
                    title: request.body.title,
                };

                // Update the labels
                this._labels.push(label);

                return [200, cloneDeep(this._labels)];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/gifts/labels')
            .reply(({ request }) => {
                // Get label
                const updatedLabel = request.body.label;

                // Update the label
                this._labels = this._labels.map((label) => {
                    if (label.id === updatedLabel.id) {
                        return {
                            ...label,
                            title: updatedLabel.title,
                        };
                    }

                    return label;
                });

                return [200, cloneDeep(this._labels)];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Labels - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/gifts/labels')
            .reply(({ request }) => {
                // Get label id
                const id = request.params.get('id');

                // Delete the label
                this._labels = this._labels.filter((label) => label.id !== id);

                // Go through gifts and delete the label
                this._gifts = this._gifts.map((gift) => ({
                    ...gift,
                    labels: gift.labels.filter((item) => item !== id),
                }));

                return [200, cloneDeep(this._labels)];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Gift Tasks - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/gifts/tasks')
            .reply(({ request }) => {
                // Get gift and task
                let updatedGift = request.body.gift;
                const task = request.body.task;

                // Update the gift
                this._gifts = this._gifts.map((gift) => {
                    if (gift._id === updatedGift._id) {
                        // Update the tasks
                        if (!gift.tasks) {
                            gift.tasks = [];
                        }

                        gift.tasks.push({
                            id: FuseMockApiUtils.guid(),
                            content: task,
                            completed: false,
                        });

                        // Update the updatedGift with the new task
                        updatedGift = cloneDeep(gift);

                        return {
                            ...gift,
                        };
                    }

                    return gift;
                });

                return [200, updatedGift];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Gifts - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/apps/gifts/all').reply(() => {
            // Clone the labels and gifts
            const labels = cloneDeep(this._labels);
            let gifts = cloneDeep(this._gifts);

            // Attach the labels to the gifts
            gifts = gifts.map((gift) => ({
                ...gift,
                labels: gift.labels.map((labelId) =>
                    labels.find((label) => label.id === labelId)
                ),
            }));

            return [200, gifts];
        });

        // -----------------------------------------------------------------------------------------------------
        // @ Gifts - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/gifts')
            .reply(({ request }) => {
                // Get gift
                const gift = request.body.gift;

                // Add an id
                gift._id = FuseMockApiUtils.guid();

                // Push the gift
                this._gifts.push(gift);

                return [200, gift];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Gifts - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/gifts')
            .reply(({ request }) => {
                // Get gift
                const updatedGift = request.body.updatedGift;

                // Update the gift
                this._gifts = this._gifts.map((gift) => {
                    if (gift._id === updatedGift._id) {
                        return {
                            ...updatedGift,
                        };
                    }

                    return gift;
                });

                return [200, updatedGift];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Gifts - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/gifts')
            .reply(({ request }) => {
                // Get the id
                const id = request.params.get('id');

                // Find the gift and delete it
                this._gifts.forEach((item, index) => {
                    if (item.id === id) {
                        this._gifts.splice(index, 1);
                    }
                });

                // Return the response
                return [200, true];
            });
    }
}
